import React from 'react'
import Event from '../assets/epic.jpg'
import Reveal from '../utils/Reveal';

const EventsDesc = () => {
  return (
    <div className='w-full bg-white  px-4 flex-row'>
    <div className='max-w-[1240px]  mx-auto grid md:grid-cols-2'>
     
      <div className='flex flex-col justify-center px-4'>

<Reveal>
        <p className='text-indigo-600 font-bold '>FLY EVENTS</p>
</Reveal>
<Reveal>
        <h1 className='md:text-4xl sm:text-3xl text-2xl font-body py-2 text-gray-800'>The FLY platform hosts all kind of events and we work with promoters to create experiences that will last a lifetime</h1>
      </Reveal>  
     
      <Reveal>
        <ul className=' list-disc ml-5 text-gray-800 pb-6 '>
          <li className='font-roboto font-semibold'> Looking for the perfect night out or ready for festival season? We've got you covered.</li>
          <li className='font-roboto font-semibold'> Gain access to exclusive discounts and offers with our affiliate event organisers</li>
          <li className='font-roboto font-semibold'> Using our powerful algorithms we provide tailored experience just for you</li>
        </ul>
        </Reveal>
        
      </div>
      
      <div className='object-cover' >
      <Reveal>
      <img className=' mx-auto rounded-2xl xl:max-w-[500px]' src={Event} alt='/' />
      </Reveal>
      </div>
      
    </div>
    <hr
  class=" flex my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
  </div>
);
};


export default EventsDesc