import React from 'react'
import Event from '../assets/Fly6.jpg'
import Reveal from '../utils/Reveal';


const FlyDesc = () => {
  return (
    <div className='w-full bg-white py-5 px-4 flex-row'>
    <div className='max-w-[1240px]  mx-auto grid md:grid-cols-2'>
     <div className='object-cover w-full' >
      <div className='py-5'>
      <img className=' mx-auto rounded-2xl xl:max-w-[500px]' src={Event} alt='/' />
      </div>
      </div>
      <div className='flex flex-col justify-center px-4'>
      <Reveal>
        <p className='text-indigo-600 font-bold '>FLY</p>
      </Reveal>
      <Reveal>
        <h1 className='md:text-4xl sm:text-3xl text-2xl font-body py-2 text-gray-800'>A new way to shop for clothes and events</h1>
      </Reveal>
      <p className='font-body py-2 text-slate-200'>
          
        </p>
        <Reveal>
        {/* <ul className='list-disc ml-5 text-gray-800'>
          <li className='font-roboto font-semibold'> Browse from a wide range of both established and rising brands</li>
          <li className='font-roboto font-semibold'> Our app equips you with the tools and inspiration to curate your signature style</li>
          <li className='font-roboto font-semibold'> Gain access to exclusive offers from brands ahead of the fashion curve</li>
        </ul> */}
        <p className='font-roboto font-semibold list-disc text-gray-800'>
        FLY ties the link between clothing and events by providing a unified platform where you can discover new brands and events. Users get events and clothing recommendations based on whether you are viewing an event or a clothing item. Imagine purchasing a ticket for your fave festival and getting new clothing recommendations on the latest and hottest festival pieces without ever having to scroll.
        </p>
        </Reveal>
      </div>
      
      
      
    </div>
    <hr
  class=" flex my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
  </div>
  );
};


export default FlyDesc