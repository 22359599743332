import {configureStore} from "@reduxjs/toolkit";

import contactReducer from "../redux/reducers/contactSlice";

const store = configureStore({
    reducer: {
        contact: contactReducer
    }
});

export default store;