import React from 'react'
import Event from '../assets/FlyLogo.jpg'

const Footer = () => {
  return (
    <div className='pb-12 bg-white'>
        <hr
  class=" w-full
  flex my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
  <div className='text-black flex items-center justify-center '>
  <img className='h-24' src={Event}/>
  <h1>© 2023 Fly Senga Ltd. All Rights Reserved.</h1>
  </div>
    </div>
  )
}

export default Footer