import Navbar from './components/Navbar';
import HeroSection from './components/HeroSection';
import FlyDesc from './components/FlyDesc';
import EventsDesc from './components/EventDesc';
import ClothingDesc from './components/ClothingDesc';
import ClothingCarousel from './components/ClothingCarousel';
import OurVision from './components/OurVision';
import Interested from './components/Interested';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
    <Navbar/>
    <HeroSection />
    <FlyDesc />
    <EventsDesc />
    <ClothingDesc />
    <ClothingCarousel />
    <OurVision />
    <Interested />
    <Footer />
    
    
    </div>
  );
}

export default App;
