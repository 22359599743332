import React from 'react';
import image from '../assets/header.jpg'
import Reveal from '../utils/Reveal';
import logo from '../assets/FlyLogoWhite.jpg'








const HeroSection = () => {
  return (
<div className="relative w-fit md:mx-10 md:my-10">
  <div className="absolute inset-0 opacity-40"></div>
  <Reveal>
    <img className=" w-full sm:h-full h-screen object-cover brightness-75"  src={image} alt="Background" />
  </Reveal>
  <div className="absolute inset-0 flex flex-col items-center justify-center text-white">
  <img src={logo} className='h-96 mx-auto object-contain '/>
    <Reveal>
      <h1 className="text-7xl  font-bold text-shadow md:hidden">
        Clothing
      </h1>
    </Reveal>
    <Reveal>
      <h1 className="text-7xl  font-bold text-shadow md:hidden">Events</h1>
    </Reveal>
    <Reveal>
   
      <h1 className="text-7xl  font-bold text-shadow md:hidden">
        ALL ON <span className="text-indigo-600">FLY</span>
      </h1>
    </Reveal>
  </div>
</div>




  )
}


export default HeroSection