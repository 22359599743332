import React, { useState } from "react";
import { CarouselProvider, Slider, Slide, ButtonBack, ButtonNext } from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";

import Fly1 from '../assets/Fly1.jpg'
import Fly2 from '../assets/Fly2.jpg'
import Fly3 from '../assets/Fly3.jpg'
import Fly4 from '../assets/Fly4.jpg'
import Fly5 from '../assets/Fly5.jpg'
import Fly6 from '../assets/Fly6.jpg'
import Reveal from "../utils/Reveal";

/* Install pure-react-carousel using -> npm i pure-react-carousel */

export default function ClothingCarousel() {
    return (
        <div className="text-gray-900 text-5xl text-center ">
    
       
    <div className="flex items-center justify-center">
        <Reveal>
            <h1 className="text-center">
            Take a look at some <span className="text-indigo-600">Fly Brands</span>
            </h1>
        </Reveal>
        </div>
      
        <div className="w-full bg-white">
        <div className="container mx-auto">
            <div className="flex items-center justify-center w-full h-full py-4 sm:py-8 px-4 bg-white">
            <Reveal>
                {/* Carousel for desktop and large size devices */}
                <CarouselProvider className="lg:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={5} visibleSlides={4} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly1} alt="black chair and white table" className="rounded-3xl object-cover object-center w-full" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                               
                                                <div className="flex h-full items-end pb-6">
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly2} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                    
                                                <div className="flex h-full items-end pb-6">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly3} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={3}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly4} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={4}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly5} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                 
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={5}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly6} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>

                {/* Carousel for tablet and medium size devices */}
                <CarouselProvider className="lg:hidden md:block hidden" naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={5} visibleSlides={2} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full flex lg:gap-8 md:gap-6 gap-14 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly1} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                    
                                                <div className="flex h-full items-end pb-6">
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly2} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly3} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={3}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly4} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={4}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly5} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={5}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly6} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-gray-800 bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                  
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>

                {/* Carousel for mobile and Small size Devices */}
                <CarouselProvider className="block md:hidden " naturalSlideWidth={100} isIntrinsicHeight={true} totalSlides={12} visibleSlides={1} step={1} infinite={true}>
                    <div className="w-full relative flex items-center justify-center">
                        <ButtonBack role="button" aria-label="slide backward" className="absolute z-30 left-0 ml-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer" id="prev">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7 1L1 7L7 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonBack>
                        <div className="w-full h-full mx-auto overflow-x-hidden overflow-y-hidden">
                            <Slider>
                                <div id="slider" className="h-full w-full flex lg:gap-8 md:gap-6 items-center justify-start transition ease-out duration-700">
                                    <Slide index={0}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly1} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                             
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={1}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly2} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={2}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly3} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                  
                                                <div className="flex h-full items-end pb-6">
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={3}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly4} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                                
                                                <div className="flex h-full items-end pb-6">
                        
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={4}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly5} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                                
                                                <div className="flex h-full items-end pb-6">
                        
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={5}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly6} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                            
                                                <div className="flex h-full items-end pb-6">
                                            
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={6}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly1} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                          
                                                <div className="flex h-full items-end pb-6">
                                               
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={7}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly2} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                         
                                                <div className="flex h-full items-end pb-6">
                                 
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={8}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly3} alt="black chair and white table" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                            
                                                <div className="flex h-full items-end pb-6">
                                              
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={9}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly4} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                                
                                                <div className="flex h-full items-end pb-6">
                                                
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={10}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly5} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                                
                                                <div className="flex h-full items-end pb-6">
                                                   
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                    <Slide index={11}>
                                        <div className="flex flex-shrink-0 relative w-full sm:w-auto">
                                            <img src={Fly6} alt="sitting area" className="object-cover object-center w-full rounded-3xl" />
                                            <div className="bg-white bg-opacity-0 absolute w-full h-full p-6">
                                   
                                                <div className="flex h-full items-end pb-6">
                                   
                                                </div>
                                            </div>
                                        </div>
                                    </Slide>
                                </div>
                            </Slider>
                        </div>
                        <ButtonNext role="button" aria-label="slide forward" className="absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400" id="next">
                            <svg width={8} height={14} viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L7 7L1 13" stroke="white" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </ButtonNext>
                    </div>
                </CarouselProvider>
                </Reveal>
            </div>
        </div>
        </div>
        
        <hr
  class=" flex my-12 h-px border-t-0 bg-transparent bg-gradient-to-r from-transparent via-neutral-500 to-transparent opacity-25 dark:opacity-100" />
        </div>
    );
}